import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../core/config';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastComponent } from '../../shared/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  apiUrl : string = Config.API_ENDPOINT;
	loginData: any;
	headers : any;
	accessToken : any;
	userId : any;
  constructor(
    public http: HttpClient,
  	public loadingService : LoaderService,
  	public snackbarCmp : ToastComponent
  ) {
    this.headers = new Headers();

    this.headers.append('Access-Control-Allow-Origin' , '*');
    this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.headers.append('Accept','application/json');
    this.headers.append('content-type','application/json');
    this.getLoginUserData();
   }

   getLoginUserData(){
    let loginUserData = JSON.parse(localStorage.getItem("loginUser"));
    if(loginUserData){
      this.accessToken = loginUserData['id'];
      this.userId = loginUserData['userId'];
    }
    
  }

  getPaymentDetails(PaymentId){

    this.getLoginUserData();

    this.loadingService.showLoading();
  
    let filterJSON = {	
      "include":['user','vendor','services','plan'],	
      "order": "createdAt DESC"
    };

  let filter = encodeURI(JSON.stringify(filterJSON));

  
    let PaymentURL = this.apiUrl + 'payments/' +PaymentId
                  + '?access_token=' + this.accessToken
                   + '&filter=' + filter;


    return new Promise((resolve, reject) => {
      this.http.get(PaymentURL).subscribe(res => {
      
        this.loadingService.hideLoading();
            resolve(res);
      },(err)=>{
        this.loadingService.hideLoading();
            this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
            reject(err);
      });
    });
  }

  getAllPayments(){
    this.getLoginUserData();

  this.loadingService.showLoading();
  
  let filterJSON = {	
      "include":['user','vendor','services','plan'],	
      // "fields":
      
      //   {
      //     "id":true,
      //     "serviceId":true,
      //     "services":true,
      //     "userId":true,
      //     "user":true,
      //     "servicePayment":true,
      //     "userPayment":true,
      //     "vendorPayment":true,
      //     "vendorId":true,
      //     "vendor":true
      //     }
      //   ,
      "order": "createdAt DESC"
    };
  

  let filter = encodeURI(JSON.stringify(filterJSON));

  let allPaymentURL = this.apiUrl + 'payments/' 
                + '?access_token=' + this.accessToken
                + '&filter=' + filter;

  return new Promise((resolve, reject) => {
    this.http.get(allPaymentURL).subscribe((res:any) => {
      
        this.loadingService.hideLoading();
        resolve(res);
      
    },(err)=>{
      this.loadingService.hideLoading();
          this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
          reject(err);
    });
  });
}

addPayment(PaymentInfo) {
  this.getLoginUserData();
  //firstName,lastName,mobileNo,companyName,email,username,password, realm
    this.loadingService.showLoading();

    let addPaymentURL = this.apiUrl + 'payments/' 
            + '?access_token=' + this.accessToken
   

  
  return new Promise((resolve, reject) => {

    this.http.post(addPaymentURL, PaymentInfo,{headers:this.headers,withCredentials: true})
      .subscribe(res => {
          this.loadingService.hideLoading();
          // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
          // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
        resolve(res);
      }, (err) => {
        this.loadingService.hideLoading();
        this.snackbarCmp.presentToast(Config.MESSAGE.USER.SIGNUP_ERR);
        reject(err);
      });
  });
}



editPayment(PaymentInfo,PaymentId,successMsg = Config.MESSAGE.USER.EDIT) {
        this.getLoginUserData();
    this.loadingService.showLoading();
    


    let editPaymentURL = this.apiUrl + 'payments/' + PaymentId + '?access_token=' + this.accessToken;
   

  
  return new Promise((resolve, reject) => {

    this.http.patch(editPaymentURL, PaymentInfo,{headers:this.headers,withCredentials: true})
      .subscribe(res => {
          this.loadingService.hideLoading();
          this.snackbarCmp.presentToast(successMsg);
        resolve(res);
      }, (err) => {
        this.loadingService.hideLoading();
        this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
        reject(err);
      });
  });
}

deletePayment(PaymentId){
        this.getLoginUserData();

  //firstName,lastName,mobileNo,companyName,email,username,password, realm

  let dialogTitle = "Delete Payment";
    let dialogContent = Config.MESSAGE.USER.DELETE_CONFIRM;
        return new Promise((resolve, reject) => {
    
  // this.dialog.openDialog(dialogTitle,dialogContent).then((isOkay)=>{
  //       if(isOkay){
        this.loadingService.showLoading();
        
        let deletePaymentURL = this.apiUrl + 'payments/' + PaymentId + '?access_token=' + this.accessToken;
       

      

        this.http.delete(deletePaymentURL,{headers:this.headers,withCredentials: true})
          .subscribe(res => {
              this.loadingService.hideLoading();
              this.snackbarCmp.presentToast(Config.MESSAGE.USER.DELETE);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
            reject(err);
         });
        // }
        // });

  })
    
    
}

}
